import { LitElement, html } from 'lit-element';
import { renderElements } from '../../elements';




export const renderHeader = (data, src, action) => data.elements
? html`
<header class="${data.styles||''}">
${renderElements(data.elements)}
</header>
`
: null


//${renderElements(data.elements)}
